.drop-list {
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: none;

}

.field-list {
    height: 250px;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}


.field-disabled {
    opacity: .6;
}

.modal-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .modal-header-left,
    .modal-header-center,
    .modal-header-right {
        flex: 1;
        display: flex
    }

    .modal-header-left {
        align-items: center;
        justify-content: flex-start;
    }

    .modal-header-center {
        align-items: center;
        justify-content: center;
        gap: 3px
    }

    .modal-header-right {
        align-items: center;
        justify-content: end;
        gap: 3px
    }
}


.default-fields-list {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    .default-fields-list-item {
        width: calc(33.33% - 10px);
        border: 1px solid #ccc;
        padding: 3px 10px;
    }

}


.custom-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 1rem;
}