.custom-forms-drop-list {
    height: calc(100vh - 300px);
    overflow-y: scroll;
    overflow-x: hidden;
    /*padding: 30px;*/
    /*background: #eeeeee;*/
    /*border: 2px dashed #cccccc;*/

}

.custom-forms-field-list {

    max-height: 425px;
    overflow-y: scroll;
    overflow-x: hidden;
}


.field-disabled {
    opacity: .6;
}

.modal-dialog.modal-custom-forms-iframe {

    padding: 0 !important;
    width: 100vw !important;
    height: 95vh;
    max-width: 95% !important;
}

.bg-modal-header-custom-forms {
    background-color: #f1f1f5;
}

.modal-shadow-border {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.modal-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .modal-header-left,
    .modal-header-center,
    .modal-header-right {
        flex: 1;
        display: flex
    }

    .modal-header-left {
        align-items: center;
        justify-content: flex-start;
    }

    .modal-header-center {
        align-items: center;
        justify-content: center;
        gap: 3px
    }

    .modal-header-right {
        align-items: center;
        justify-content: end;
        gap: 10px
    }
}


.dotted {
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dotted-long {
    width: 280px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}



.custom-forms-droplist-buttons {
    button {
        padding: 7px;
        transition: all 300ms linear;

        &:hover {
            color: red
        }
    }
}

.offcanvas-start {
    width: 100vw !important;
}

/* ENDLESS ROTATE */
.rotate {
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.cf-boxes-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .cf-box {
        width: 105px;
        height: 105px;
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        transition: all 300ms linear;

        &:hover {
            border: 1px solid #000;
        }

        .icon {
            font-size: 30px;
        }
    }
}


.option-box-container {
    h4 {
        font-weight: 300;
        margin-left: 15px;
    }

    .option-box {
        border: 1px dashed #d9d9d9;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 15px;
    }

    .new-option-box {
        border-color: green;
    }

    .add-option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}


.rti--container {
    width: 100% !important;
}

.cf-inner-left {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 10px;
    background: #ffffff;
}



.cf-inner-center {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 40px;
    background: #dddddd;

    .cf-innet-center-wrapper {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 40px;
        background: #ffffff;
    }
}

.cf-inner-right {
    height: 100%;
    min-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 10px;
    background: #ffffff;
}

.custom-forms-control {
    border: none;
    border-bottom: 1px solid #aaaaaa;
    border-radius: 0;

}

.cf-light-blue {
    background: #f2f9ff;
}