.approve-template-flist{
    background: #fff;
    margin-bottom: 3px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
        background: #f1f1f9;
    }
}