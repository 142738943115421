.key-terms {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;


    form {
        width: 100%;

        input,
        .react-select__control,
        textarea {
            width: 100%;
            border-radius: 10px !important;

        }

        label {
            color: #a1a8b0;
        }

        .col-md-6 {
            margin-bottom: 10px !important;
            padding-left: 0 !important;
        }
    }



    .process-type-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}