@import '../../assets/scss/config/onedocs_variables.scss';

.ap-keyterms-list {
    list-style: none;
    padding: 0;

    .ap-keyterms-list-item {

        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(233, 236, 239, 0.5568627451);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }

        .ap-keyterms-list-item-checkbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            width: 220px;
        }

    }
}

.ap-files-list {
    list-style: none;
    padding: 0;

    .ap-files-list-item {

        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(233, 236, 239, 0.5568627451);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }

        .ap-files-list-item-checkbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            width: 100%;
        }

    }
}



.steps {
    padding-top: 0;

    .step {
        /* padding: 20px; */
        width: 100%;
        /* background: #e9e9e9; */
        margin-bottom: 25px;
        border-radius: 5px;

        .step-number {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px !important;
        }

        .recipients-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .recipients {
                width: 100%;
                background: #ffffff;
                border: 1px solid #e9e9e9;

                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 5px;
                border-radius: 5px;

                .recipients-new {
                    width: 240px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        text-align: left;
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                        border-color: #e9e9e9;
                        outline: 0;
                        border: 0;
                        width: 100%;
                    }

                    .add-recipient-button {
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                        border: 1px solid #050027;
                        outline: 1px solid #050027;
                    }
                }

                .recipient-group {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .recipient-label {
                        background: #fff;
                        border: 1px solid #DFDFDF;
                        color: #555555;
                        padding: 5px;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 5px;
                        height: 40px;
                    }

                    .recipient-settings {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 10px;

                        .form-check-inline {
                            margin-right: 0;
                            margin-bottom: 0;
                            line-height: 14px;
                        }

                        .btn {
                            padding: 0;
                            width: 20px;
                            height: 20px;
                            color: #303030;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;
                            cursor: pointer;

                            &:hover {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }

        .step-settings {
            padding: 0;

            padding-top: 0px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            font-size: 13px;
            font-weight: 400;
            line-height: 13px;
        }
    }
}

.steps-edit {
    position: relative;
    padding: 20px;
    padding-top: 0;

    .floting-close-icon {
        position: absolute;
        top: 0px;
        right: 5px;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        h4 {
            max-width: 400px;
            text-align: center;
        }
    }

    .step {
        padding: 10px;
        width: 100%;
        background: #fff;
        border: 1px solid #e9e9e9;
        margin-bottom: 20px;
        border-radius: 5px;

        .step-number {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                font-size: 1.2em;
                color: #4b4b5a;
                margin-bottom: 10px !important;
            }
        }

        .recipients {
            background: #ffffff;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 5px;
            border-radius: 5px;
            margin-bottom: 10px;

            .recipient {
                width: 100%;
                overflow: hidden;
                border-radius: 5px;

                .recipient-group {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 2px;

                    .recipient-label {
                        background: #fff;
                        border: 1px solid #DFDFDF;
                        color: #555555;
                        padding: 5px;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 5px;
                        height: 40px;
                    }

                    .recipient-settings {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 10px;

                        .form-check-inline {
                            margin: 0;
                            padding: 0;
                            line-height: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 3px;

                            .form-check-input {
                                margin: 0;
                                padding: 0;
                            }

                            .form-check-label {
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 13px;
                                white-space: nowrap;
                            }
                        }

                        .btn {
                            padding: 0;
                            width: 20px;
                            height: 20px;
                            color: #303030;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;
                            cursor: pointer;

                            &:hover {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }

        .step-settings {
            padding: 10px;
            height: 50px;
            padding-left: 0 !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
    }
}

.form-check-input:checked {
    border-color: $od-focused;
    background: $od-focused;
    background-repeat: no-repeat;
}

.ap-keyterms-list-item-checkbox {
    input:checked {
        border-color: $od-focused;
        background: $od-focused;
        background-repeat: no-repeat;
    }
}

.message-block {
    h4 {
        font-size: 1.125rem;
        color: #4b4b5a;
        margin-bottom: 10px !important;
    }
}