.pos-files {

    .infinite-scroll-component {
        min-height: 185px;
        overflow: auto;
    }

    .pos-file-list {

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #a1a8b0;

        .pos-list-head {
            font-weight: bold;
        }

        .title {
            width: 165px;
            padding: 5px;
            display: flex;

            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            .dotted {
                width: 145px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                a {
                    font-weight: 500;
                    color: #303030;
                }
            }
        }

        .status {
            padding: 5px;
            width: 140px;

        }

        .version {
            padding: 5px;
            width: 140px;
        }

        .date {
            padding: 5px;
            width: 180px;
        }

        .user {
            padding: 5px;
            width: 70px;
        }


    }
}


.file-icon-filetable {
    width: 35px;
    height: 35px;
    object-fit: contain;

    svg {
        width: 35px;
        height: 35px;
    }
}

.dotted-long-filetable {
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}