@import '../../assets/scss/config/_onedocs_variables.scss';

.test-page {
    margin-top: 20px;
    width: 100%;

}

.slate::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari ve Opera için */
}

.slate {
    -ms-overflow-style: none;
    /* IE ve Edge için */
    scrollbar-width: none;
    /* Firefox için */
}

.slate-wrapper {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .slate-form {
        width: 40%;
        padding: 30px;
    }

    .slate {
        width: 100%;
        max-width: 1025px;
        border: 1px solid $od-stroke-gray;
        border-radius: 4px;
        padding: 0;


        height: calc(100vh - 176px);
        overflow: auto;

        .css-1vdn1ty {
            color: #000000;
        }

        .css-vbnk1l {
            background: lightgreen;
        }

        .hf-placeholder {
            width: 100%;
            height: auto;
            display: flex;
            padding: 10px;
            background: white;

        }
    }
}

.toolbar {
    width: 100% !important;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;

    .custom-slate-toolbar {
        width: 100% !important;

        z-index: 100;
        background: $od-bg-white;
        border-bottom: 1px solid $od-stroke-gray;
        border-radius: 0;
        box-shadow: 0 0 4px 1px rgb(0 8 34 / 7%);
        padding: 5px 5px !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;



        span,
        div,
        button {

            border-radius: 4px;
            padding: 5px;
            margin: 0 !important;
        }

        .slate-toolbar-dd-menu {
            max-height: 300px;
            overflow-y: auto;
        }

    }


}


.custom-slate-editor-content {
    min-height: 400px;
}

.custom-slate-editor {


    font-family: 'Arial', sans-serif;

    [data-highlight-color="yellow"] {
        background-color: yellow;
    }

    [data-highlight-color="blue"] {
        background-color: lightblue;
    }

    [data-highlight-color="green"] {
        background-color: lightgreen;
    }

    background: $od-bg-white;

    /* Tüm tarayıcıların varsayılan stil özelliklerini sıfırlama */
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: inherit;
    }

    /* Başlık etiketlerinin varsayılan stil özelliklerini sıfırlama */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
        color: #000000;
    }

    h1 {
        font-size: 20pt;
    }

    h2 {
        font-size: 18pt;
    }

    h3 {
        font-size: 16pt;
    }

    h4 {
        font-size: 14pt;
    }

    h5 {
        font-size: 12pt;
    }

    h6 {
        font-size: 10pt;
    }



    /* Paragraf etiketinin varsayılan stil özelliklerini sıfırlama */
    p {
        margin: 0;
        padding: 0;
        font-size: 12pt;
    }

    /* Alıntı etiketinin varsayılan stil özelliklerini sıfırlama */
    blockquote {
        margin: 0;
        padding: 0;
    }

    /* Ön biçimli (pre) etiketinin varsayılan stil özelliklerini sıfırlama */
    pre {
        margin: 0;
        padding: 0;
        font-family: monospace;
    }

    /* Sırasız liste (ul) etiketinin varsayılan stil özelliklerini sıfırlama */
    ul {
        margin: 0;
        padding: 0;
    }

    /* Sıralı liste (ol) etiketinin varsayılan stil özelliklerini sıfırlama */
    ol {
        margin: 0;
        padding: 0;
    }

    /* Liste öğesi (li) etiketinin varsayılan stil özelliklerini sıfırlama */
    li {
        margin: 0;
        padding: 0;
    }

    /* Hiperlink (a) etiketinin varsayılan stil özelliklerini sıfırlama */
    a {
        text-decoration: none;
        color: inherit;
    }

    border: none;
    border-radius: 0;
    padding: 20px;
    margin-bottom: 0;






    &:focus {
        outline: none;
    }




}

.header-placeholder,
.footer-placeholder {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    background: #ffffff;
}

.bd-bottom {
    border-bottom: 1px solid rgb($od-stroke-gray, .5) !important;
    border-radius: 4px 4px 0 0 !important;
}

.bd-top {
    border-top: 1px solid rgb($od-stroke-gray, .5) !important;
    border-radius: 0 0 4px 4px !important;
}

.slate-table-wrapper {

    .slate-table {
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;

        &:hover {

            .table-add-row,
            .table-add-column {
                opacity: 1;


            }
        }

        .table-add-row {
            opacity: 0;
            transition: all .3s linear;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            margin-bottom: 10px;
            margin-top: 0;
            white-space: nowrap;
            font-size: 1rem;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            bottom: -19px;
            color: $od-stroke-gray;

            &:hover {
                color: $od-stroke-green;

                &::before,
                &::after {
                    background: $od-stroke-green;
                }
            }

            &::before {
                transition: all .3s linear;
                content: '';
                width: 100%;
                height: 5px;
                background: $od-stroke-gray;
                border-radius: 5px;
            }

            &::after {
                transition: all .3s linear;
                content: '';
                width: 100%;
                height: 5px;
                background: $od-stroke-gray;
                border-radius: 5px;
            }
        }

        .table-add-column {
            transition: all .3s linear;
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            /* Dikey olarak ortala */
            gap: 5px;
            white-space: nowrap;
            font-size: 1rem;
            cursor: pointer;
            position: absolute;
            top: 0;
            /* Tablonun en üst kısmına yerleştir */
            right: -6px;
            /* Tablonun sağ tarafından bir miktar dışarıda */
            height: 100%;
            color: $od-stroke-gray;

            /* Tablonun yüksekliğine eşit yap */
            span {
                transform: rotate(90deg);
                z-index: 999;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 5px;
            }

            &:hover {
                color: $od-stroke-green;

                &::before,
                &::after {
                    transition: all .3s linear;
                    background: $od-stroke-green;
                }
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 5px;
                /* Dikey çizgiler için genişlik */
                background: $od-stroke-gray;
                border-radius: 5px;
                /* Çizgilerin rengi, değişkeninizin değeri */
            }

            &::before {
                top: 0;
                left: 50%;
                /* Yatay olarak ortala */
                height: 100%;
                /* Üst çizginin uzunluğu */
            }

            &::after {
                bottom: 0;
                left: 50%;
                /* Yatay olarak ortala */
                height: 100%;
                /* Alt çizginin uzunluğu */
            }
        }



    }
}