.add-pipeline-2 {
    display: flex;

    gap: 5px;
    align-items: start;
    justify-content: center;

    div {
        width: 100%;
        height: 100%;
    }
}