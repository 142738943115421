.automation-create {
    display: flex;
    border-bottom: 1px solid #e5e5e5;

    .automation-create-left,
    .automation-create-right {
        flex: 1;
        min-height: 600px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 50px;
    }

    .automation-create-left {
        border-right: 1px solid #e5e5e5;

        .automation-create-left-when {
            background: #f5f5f5;
            width: 100%;
            padding: 10px 20px;
            border-radius: 5px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .automation-create-left-when-logo {
                background: white;
                padding: 5px;
                border-radius: 5px;
                position: relative;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                /* SVG LOGO */
                img {
                    width: 40px;
                    height: 40px;
                }

            }

            .automation-create-left-when-content {
                .automation-create-left-when-content-title {
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .automation-create-left-when-content-description {
                    font-size: 1rem;
                    line-height: 1rem;
                    opacity: 0.7;
                }
            }

        }

        .automation-create-left-when-rule {
            background: #f5f5f5;
            width: 100%;
            padding: 10px 20px;
            border-radius: 5px;
            margin-bottom: 20px;

            .automation-create-left-when-rule-content {
                margin-top: 20px;
            }

        }
    }

    .automation-create-right {

        .automation-create-right-then {
            background: #f5f5f5;
            width: 100%;
            padding: 10px 20px;
            border-radius: 5px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .automation-create-right-then-logo {
                background: white;
                padding: 5px;
                border-radius: 5px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .automation-create-right-then-content {
                .automation-create-right-then-content-title {
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .automation-create-right-then-content-description {
                    font-size: 1rem;
                    line-height: 1rem;
                    opacity: 0.7;
                }
            }

        }

        .automation-create-right-then-rule {
            background: #f5f5f5;
            width: 100%;
            padding: 10px 20px;
            border-radius: 5px;
            margin-bottom: 20px;



        }



    }
}

.automation-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;


    padding: 10px;

}