.automation-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;

    .automation-list-item {
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px solid #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        transition: all 0.3s linear;

        &:hover {
            background-color: #f9f9f9;
        }

        .automation-list-item-description {
            .automation-list-item-description-label {
                border: 1px solid #e5e5e5;
                border-radius: 5px;
                padding: 5px 10px;
                font-size: 0.8rem;
                line-height: 0.8rem;
                font-weight: 500;
            }
        }

        .automation-list-item-action {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

        }
    }

    /* first border top 1px */
    .automation-list-item:first-child {
        border-top: 1px solid #f9f9f9;
    }
}

.automation-list-header {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    transition: all 0.3s linear;

    .automation-list-header-nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        .automation-list-header-nav-item {
            padding: 10px;
            border-radius: 5px;
            font-size: .9rem;
            line-height: .9rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s linear;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);

            &:hover {
                background-color: #f5f5f9;
            }
        }

        .automation-list-header-nav-item.active {
            background-color: #f5f5f9;
        }

    }


}