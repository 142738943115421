.automation {
    padding: 0;

    .automation-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        .automation-header-title {
            font-size: 1.3rem;
            line-height: 1.3rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            gap: 5px;

            button {
                padding: 0;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }



    .automation-body {
        min-height: 400px;
        border-top: 1px solid #e5e5e5;

        .automation-action-bar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .automation-list {
            padding: 10px;
        }
    }
}

.modal-dialog.modal-70w.modal-xl.modal-dialog-centered {
    width: 90%;
    max-width: 90%;
}