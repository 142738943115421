@import '../../assets/scss/config/_onedocs_variables.scss';

.process-overview-screen {


    * {
        box-sizing: border-box;

    }

    height: 100%;
    background: $od-bg-secondary;

    .pos-header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        gap: 20px;


        .pos-header-left {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;

            .pos-header-left-title {
                width: 100%;

                .input-title {
                    font-weight: 500;
                    font-size: 22px;
                    width: 100%;
                    border: none;
                    outline: none;

                    &:focus {
                        border: 1px solid #cccccc57;
                        outline: none;
                        padding: 3px;
                    }
                }
            }

        }

        .pos-header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            .pos-header-right-tags {
                .add-tags {


                    .select-tag {
                        min-width: 49px;
                        height: 49px;
                        width: 49px;
                        background: white;
                        color: #ccc;
                        border: 1px dashed #ccc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        padding: 11px;



                        .counter {
                            padding: 5px;
                            font-size: 12px;
                            line-height: 12px;
                            position: absolute;
                            top: 0px;
                            right: 2px;
                            background: #a9a9a9;
                            color: white;
                            border-radius: 100%;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }

            .pos-header-right-assign-users {
                .footer-assign-user {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    .title {
                        font-size: 1.3rem;
                        font-weight: 600;
                        margin-bottom: 1rem;
                        text-align: center;
                    }

                    .user-list {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        flex-direction: row-reverse;
                        border: 1px solid #000000;
                        border-radius: 50px;
                        /* padding-left: 20px; */
                        padding-top: 5px;
                        padding-bottom: 5px;


                        .dropdown {
                            width: 50px;
                            height: 50px;

                            .user-add {
                                padding: 7px;
                            }

                            .dropdown-menu {
                                margin-left: -45px;
                            }

                        }



                        .user {
                            height: 50px;
                            width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            margin-left: -20px;
                            z-index: 9;

                        }

                        .user-add {
                            height: 50px;
                            width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            margin-left: -10px;
                            z-index: 8;
                            background: white;
                            color: #ccc;
                            border: 1px dashed #ccc;

                            .plus-icon {
                                position: absolute;
                                bottom: 3px;
                                right: 3px;
                                background: #a9a9a9;
                                color: white;
                                border-radius: 100%;
                                width: 15px;
                                height: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }

            .pos-header-right-private {
                .private-button {
                    width: 49px;
                    height: 49px;
                    background: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px dashed #ccc;
                    border-radius: 100px;
                    color: #ccc;

                    svg {
                        color: #ccc;
                    }

                }
            }
        }
    }

    .pos-menu {
        background-color: #fff;
        border-bottom: 1px solid #e5e5e5;
        padding: 0 20px;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;

            li {
                cursor: pointer;
                border-bottom: 2px solid transparent;
                display: flex;

                &.active {
                    border-bottom: 2px solid #000;
                }



            }


        }
    }

    .pos-status-bar {
        padding: 20px;
        display: flex;
        width: 100%;

        .statuses-more {

            .btn-more {
                margin-left: -3px;
                height: 36px;
                width: 100%;
                background: #BABAC8;
                color: white !important;
                border: none;
                outline: none;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;

                .btn-link {
                    color: white !important;
                }

                &.active {
                    background: $od-focused !important;
                }

            }

        }

        .statuses {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 5px;
            flex-wrap: nowrap;
            border-radius: 5px;
            overflow: hidden;
            width: 100%;



            .status {
                height: 36px;
                width: 100%;
                background: #BABAC8;
                color: white;
                width: 100%;
                text-align: center;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -18px;
                    /* Sağ tarafın genişliğini ayarlayabilirsiniz */
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 18px 0 18px 18px;
                    /* Üçgenin boyutunu ayarlayabilirsiniz */
                    border-color: white white white #BABAC8;
                    margin-right: 10px;
                    z-index: 9;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -20px;
                    /* Sağ tarafın genişliğini ayarlayabilirsiniz */
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 18px 0 18px 18px;
                    /* Üçgenin boyutunu ayarlayabilirsiniz */
                    border-color: #BABAC8 #BABAC8 #BABAC8 transparent;
                    margin-right: 10px;
                    z-index: 99;
                }

                &.active {
                    background: $od-focused;

                    &::before {

                        border-color: white white white $od-focused;

                    }

                    &::after {
                        border-color: #BABAC8 #BABAC8 #BABAC8 transparent;

                    }
                }

                &.active-prev {

                    &::after {
                        border-color: $od-focused $od-focused $od-focused transparent;

                    }
                }

                &:last-of-type {
                    &::after {
                        border: none !important;
                    }

                    &::before {
                        border: none !important;
                    }

                    border-radius: 0 5px 5px 0;
                }




            }
        }
    }

    .pos-description {
        padding: 0 20px;

        textarea {
            height: 60px;
            padding: 5px;
            border-radius: 10px;
        }
    }

    .pos-content-wrapper {
        padding: 20px;
        display: flex;
        gap: 20px;

        div {
            .card {

                background-color: transparent;
                border: none !important;




                .card-header {
                    background-color: #fff;
                    border-radius: 10px 10px 0 0;
                    background: #fff;
                    border: 1px solid #D9D9D9;



                    button {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        border: 1px solid $od-focused;
                        color: $od-focused;
                        height: 30px;
                        width: auto;
                        padding: 5px 10px;

                        font-size: 12.6px;

                        span {
                            color: $od-focused;
                        }

                        &:hover {
                            background-color: $od-focused;
                            color: #fff;

                            span {
                                color: #fff;
                            }
                        }
                    }
                }

                .card-body {
                    border-radius: 0 0 10px 10px;
                    background: #fff;
                    border: 1px solid #D9D9D9;
                    border-top: 1px solid transparent;
                }
            }
        }

        .pos-content-wrapper-left {
            width: 60%;
            border-radius: 5px;
        }

        .pos-content-wrapper-right {
            width: 40%;
            border-radius: 5px;
        }
    }
}

.disabled-div {
    pointer-events: none;
    opacity: 0.6;
}


.select-contracts-companies {
    div {
        border-radius: 10px !important;
    }
}


.process-approval-overview {
    padding: 30px;

    .process-approval-overview-inner {
        background: white;
        border-radius: 10px;
        padding: 40px;

        .steps {


            .steps-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 60px;
                position: relative;
                height: 100%;
                transition: all 0.3s linear;


                &:hover {
                    .step-item-wrapper {
                        .step-item-wrapper-delete {
                            right: 0;
                        }
                    }
                }


                .step-item-number {
                    font-size: 1.4rem;
                    margin-right: 30px;
                    white-space: nowrap;
                    width: 120px;


                }


                .connector {
                    width: 1px;
                    height: 200px;
                    background: #dadada;
                    position: absolute;
                    left: calc(50% + 60px);
                    z-index: 1;
                    bottom: -50%;
                }

                .step-item-deleted {
                    width: 160px;
                    background: #fff;
                    border-radius: 10px;
                    border: 1px solid #dadada;
                    padding: 5px 10px;
                    position: absolute;
                    left: calc(50% - 20px);
                    z-index: 1;
                    top: -10px;
                    z-index: 9;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .step-item-wrapper {
                    z-index: 2;
                    position: relative;
                    padding: 30px;
                    border-radius: 10px;
                    width: 100%;
                    background: #F6F6F6;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 20px;
                    max-width: 100%;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    -ms-overflow-style: none;



                    /* IE and Edge /
                                          scrollbar-width: none;  / Firefox */
                    &::-webkit-scrollbar {
                        display: none;
                    }


                    .step-item-user {
                        background: white;
                        border-radius: 10px;
                        min-width: 260px;
                        border: 1px solid #fff;
                        transition: all 0.3s linear;
                        cursor: pointer;

                        &:hover {
                            border: 1px solid $od-focused;
                        }

                        .step-item-user-inner {
                            padding: 20px;
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 10px;
                            position: relative;

                            .step-item-user-detail {
                                .step-item-user-name {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    gap: 5px;
                                    font-size: 1rem;
                                }



                            }

                            .last-process-date {
                                position: absolute;
                                bottom: 2px;
                                right: 2px;
                                font-size: 10px;
                            }
                        }

                    }

                    .step-item-wrapper-delete {
                        position: absolute;
                        top: 0;
                        right: -100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        padding: 10px;
                        background: #fff;
                        border: 1px solid #F6F6F6;
                        border-radius: 5px;
                        transition: all 0.3s linear;
                        cursor: pointer;
                        color: red;




                    }

                }
            }
        }
    }
}

.modal-files-list {
    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        border-radius: 10px;
        background: #F6F6F6;
        margin-bottom: 10px;
        color: blue;
        text-decoration: none;

        &:hover {
            background: #e5e5e5;
        }

        .file-name {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.modal-message-box {
    padding: 10px;
    background: #F6F6F6;
    border-radius: 10px;
    margin-bottom: 10px;
}

.modal-user-assigned {
    padding: 10px;
    background: #F6F6F6;
    border-radius: 10px;
    margin-bottom: 10px;
}

.custom-pa-tooltip .tooltip-inner {
    max-width: 500px;
    width: 500px;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    padding: 10px;
    font-size: 14px;
    text-align: left;
}

.process-approval-action {
    padding: 40px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;

    .process-approval-action-feed {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;


        .process-approval-action-feed-detail {
            .process-approval-action-feed-detail-date {
                font-size: 10px;
                color: rgba(61, 60, 64, .6);

            }
        }
    }

    .process-approval-action-files {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        a {
            color: blue;

        }
    }

    .process-approval-action-userfiles {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;

        .userfile {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 1px;
            flex-direction: column;

            .filename {
                color: #000;
                font-weight: 600;
            }

            a {
                color: blue;

            }
        }

    }

    .process-approval-action-message {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;

        .message-icon {}

        .message-content {
            width: 100%;
            min-height: 30px;
            border-radius: 5px;
            background: rgba(235, 235, 235, 1);
            padding: 15px;
        }
    }
}