.process-status {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: .7rem;

    &.status-approved {
        background: #397C45;
        color: white;
    }

    &.status-uploaded {
        background: #FF6666;
        color: white;
    }

    &.status-assigned {
        background: #FF7B1B;
        color: white;
    }

    &.status-pending {
        background: #2D42FF;
        color: white;
    }

    &.status-created {
        background: #888888;
        color: white;
    }




}