.contract-overview-modal {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    /*     border: 5px solid yellow;
 */
}

.contract-overview-modal-dialog {
    padding: 40px;
    width: 100% !important;
    max-width: 100% !important;
    height: 100%;
    margin: 0;
    /*     border: 5px solid red;
 */
}

.contract-overview-modal-backdrop {
    background-color: black;
    opacity: .9;
}