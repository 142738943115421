.fill-form-custom-fields {
    padding: 0 !important;

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 20px;
    }
}