.new-process-modal-files {

    .infinite-scroll-component {
        min-height: 185px;
        overflow: auto;
    }

    .pos-file-list {

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e051;
        color: #a1a8b0;



        .pos-list-head {
            font-weight: bold;

        }

        .file-checkbox {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .title {
            width: 100%;
            padding: 5px;
            display: flex;

            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            .dotted {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                a {
                    font-weight: 500;
                    color: #303030;
                }
            }
        }

        .status {
            padding: 5px;
            width: 140px;

        }

        .version {
            padding: 5px;
            width: 140px;
        }

        .date {
            padding: 5px;
            width: 250px;
            text-align: center;
        }

        .user {
            padding: 5px;
        }


    }
}


.file-icon-filetable {
    width: 35px;
    height: 35px;
    object-fit: contain;

    svg {
        width: 35px;
        height: 35px;
    }
}

.dotted-long-filetable {
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}