.af-wrapper {
    max-height: 600px;
    overflow-y: auto;
    padding-top: 15px;

    .activity-feed {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .af-item {
            margin-bottom: 15px;
            min-width: 600px;
            border-bottom: 1px solid #e2e7f1;
            padding: 10px;

            .icon {
                color: #a1a8b0;
                fill: #a1a8b0;

            }

            .af-item-header {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;

                .af-item-header-detail {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;



                    .af-item-header-detail-message {

                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                        margin-bottom: 5px;
                        white-space: nowrap;

                    }

                    .af-item-header-detail-date {
                        font-size: .6rem;
                        color: #a1a8b0;
                        cursor: pointer;
                        white-space: nowrap;
                    }

                }

            }

            .activity-feed__item__details {
                margin-left: 52px;

                .title {
                    font-weight: 500;
                }

                .oldNewDetail {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;

                    .oldValue {
                        text-decoration: line-through;
                        color: #a1a8b0;
                    }

                    .newValue {
                        color: #000;
                        background: #eeeefe;
                        padding: 2px 5px;
                    }
                }

                .comment-box {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;

                    .message {
                        color: #000;
                        background: #eeeeee;
                        padding: 5px 10px;


                    }
                }
            }
        }
    }
}