.select-contracts-type {
    #react-select-3-listbox {
        .css-9gakcf-option {
            background-color: #050027 !important;
            color: white !important;
        }

        .css-1n7v3ny-option {
            background-color: #050027 !important;
            color: white !important;
        }
    }

    .css-14el2xx-placeholder {
        color: #a1a8b0 !important;
        font-size: 14px;
    }
}

.select-approve-template {
    min-width: 200px;
    #react-select-3-listbox {
        .css-9gakcf-option {
            background-color: #050027 !important;
            color: white !important;
        }

        .css-1n7v3ny-option {
            background-color: #050027 !important;
            color: white !important;
        }
    }

    .css-14el2xx-placeholder {
        color: #a1a8b0 !important;
        font-size: 14px;
    }
}