@import '../../../../assets/scss/config/_onedocs_variables.scss';

.comments-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    .comments {
        padding: 10px;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .comment {
            margin-bottom: 4px;

            .message {
                padding: 5px;
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .comment-avatar {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .comment-content {
                    width: 100%;
                    background: #fff;
                    padding: 10px;
                    border-radius: 0 15px 15px 15px;
                    margin-bottom: 10px;

                    .comment-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .comment-author {
                            font-weight: lighter;
                        }

                        .comment-date {
                            font-size: 12px;
                            color: #9e9e9e;
                        }
                    }

                    /* comment-header closing brace */
                }

                /* comment-content closing brace */
            }

            /* message closing brace */
        }

        /* comment closing brace */
    }

    /* comments closing brace */

    .write-comment-block {
        width: 100%;
        background: #ffffff;
        padding: 10px;
        border: 1px solid #D9D9D9;
        border-radius: 10px;

        .comment-input {
            width: 100%;
            min-height: 80px;
            border: none !important;
            outline: none !important;
            background: #ffffff;
            font-size: 14px;
            resize: none;
            margin: 0;
            padding: 0;

            * {
                border-color: #ffffff !important;
                outline: none !important;

                &::placeholder {
                    color: #9e9e9e;
                }
            }

            /* universal (*) selector closing brace */
        }

        /* comment-input closing brace */
    }

    /* write-comment-block closing brace */
}

/* comments-container closing brace */

.btn-send-button-empty,
.btn-send-button-full {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid $od-focused;
    color: $od-focused;
    height: 30px;
    width: auto;
    padding: 5px 10px;

    span {
        color: $od-focused;
    }

    &:hover {
        background-color: $od-focused;
        color: #fff;

        span {
            color: #fff;
        }
    }

    /* send-button-text closing brace */
}

.border-suggest {
    border: 1px solid #D9D9D9;
}

.user-list {

    border-bottom: 1px solid #D9D9D9;
    padding: 5px 8px;
    margin: 0;
}

.comment-input__suggestions {
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
}