.modal-dialog.modal-google-iframe {

    padding: 0 !important;
    width: 100vw !important;
    height: 95vh;
    max-width: 95% !important;
}

.google-docs-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.drive-iframe-wrapper {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .gdocs-iframe-left {
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .gdocs-iframe-right {
        width: 600px;
        height: 100%;
        padding: 0;
        background: #ffffff;
        border-left: 1px solid #E8E8E9;
        position: relative;

        .gdocs-iframe-right-toggle {
            position: absolute;
            bottom: 10px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .ai-panel {
            height: 100%;
            width: 100%;

            .ai-panel-header {
                padding: 30px 15px;
                border-bottom: 1px solid #E8E8E9;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
                height: 40px;

                .ai-panel-title {
                    font-size: 20px;
                    font-weight: 300;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                }

                .ai-panel-close {
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 600;
                    color: #000000;
                }
            }

            .ai-panel-content {
                height: calc(100% - 50px);
                padding: 15px;
                position: relative;
                width: 100%;

                .message-area {
                    height: calc(100% - 250px);
                    overflow: hidden;
                    overflow-y: auto;
                }

                .chat-area {
                    background: white;

                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 250px;
                    display: flex;

                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;

                    textarea {
                        border-color: #E8E8E9;

                        /* Placeholder color */
                        ::placeholder {
                            color: #E8E8E9;
                        }
                    }




                    .questions {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: flex-end;
                        width: 100%;
                        gap: 10px;

                        .ready-question {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            flex-direction: column;
                            text-align: left;
                            border-radius: 5px;
                            width: 100%;
                            padding: 8px 14px;
                            cursor: pointer;
                            border: 1px solid #E8E8E9;
                            background: #ffffff;


                            &:hover {
                                background: #ffffff;
                                border-color: #000;
                                color: #000;
                            }

                            &:focus {
                                background: #ffffff;
                                border-color: #4b4b4a;
                                color: #4b4b4a;
                            }

                            &:active {
                                background: #ffffff;
                                border-color: #4b4b4a;
                                color: #4b4b4a;
                            }

                            &:last-child {
                                margin-bottom: 10px;
                            }

                            .title {
                                font-size: 0.875rem;
                                font-weight: 600;
                                color: #2D42FF;
                            }

                            .description {
                                font-size: 12px;
                                font-weight: 300;
                                color: #4b4b4a;
                            }

                        }
                    }

                    .chat-action {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .btn-action {
                            border: 1px solid #2D42FF;
                            color: #2D42FF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.g-editor-tab {
    display: flex;
    height: 100%;
    width: 100%;

    .g-editor-tab-menu {
        padding: 20px;
        border-right: 1px solid #E8E8E9;
        position: relative;
    }

    .g-editor-tab-content {
        height: calc(100vh - 150px);
        width: 100%;
    }

}