.assign-users {
    height: 100%;
    width: 100%;
    display: flex;

    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 10px;

    .title {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
    }

    .assign-users-user-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        position: relative;
        border-radius: 40px;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;


        .dropdown {
            width: 40px;
            height: 40px;

            /* .dropdown-menu {
                margin-left: -45px;
            } */

        }



        .assign-users-user {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left: -10px;
            z-index: 9;

            .assign-users-user-item {
                * {
                    font-size: 12px !important;
                }
            }
        }

        .assign-users-user-add {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left: -10px;
            z-index: 8;
            background: white;
            color: #a1a8b0;
            border: 1px dashed #a1a8b0;
            padding: 10px;

            .plus-icon {
                position: absolute;
                bottom: 3px;
                right: 9px;
                background: #a9a9a9;
                color: white;
                border-radius: 100%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}