@import '../../assets/scss/config/_onedocs_variables.scss';

.process-overview-screen {


    * {
        box-sizing: border-box;

    }

    height: 100%;
    background: #F6F6F6;

    .pos-header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        gap: 20px;


        .pos-header-left {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;

            .pos-header-left-title {
                width: 100%;

                .input-title {
                    font-weight: 500;
                    font-size: 22px;
                    width: 100%;
                    border: none;
                    outline: none;

                    &:focus {
                        border: 1px solid #cccccc57;
                        outline: none;
                        padding: 3px;
                    }
                }
            }

        }

        .pos-header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            .pos-header-right-tags {
                .add-tags {


                    .select-tag {
                        min-width: 49px;
                        height: 49px;
                        width: 49px;
                        background: white;
                        color: #ccc;
                        border: 1px dashed #ccc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        padding: 11px;



                        .counter {
                            padding: 5px;
                            font-size: 12px;
                            line-height: 12px;
                            position: absolute;
                            top: 0px;
                            right: 2px;
                            background: #a9a9a9;
                            color: white;
                            border-radius: 100%;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }

            .pos-header-right-assign-users {
                .footer-assign-user {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    .title {
                        font-size: 1.3rem;
                        font-weight: 600;
                        margin-bottom: 1rem;
                        text-align: center;
                    }

                    .user-list {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        flex-direction: row-reverse;
                        border: 1px solid #000000;
                        border-radius: 50px;
                        /* padding-left: 20px; */
                        padding-top: 5px;
                        padding-bottom: 5px;


                        .dropdown {
                            width: 50px;
                            height: 50px;

                            .user-add {
                                padding: 7px;
                            }

                            .dropdown-menu {
                                margin-left: -45px;
                            }

                        }



                        .user {
                            height: 50px;
                            width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            margin-left: -20px;
                            z-index: 9;

                        }

                        .user-add {
                            height: 50px;
                            width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            margin-left: -10px;
                            z-index: 8;
                            background: white;
                            color: #ccc;
                            border: 1px dashed #ccc;

                            .plus-icon {
                                position: absolute;
                                bottom: 3px;
                                right: 3px;
                                background: #a9a9a9;
                                color: white;
                                border-radius: 100%;
                                width: 15px;
                                height: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }

            .pos-header-right-private {
                .private-button {
                    width: 49px;
                    height: 49px;
                    background: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px dashed #ccc;
                    border-radius: 100px;
                    color: #ccc;

                    svg {
                        color: #ccc;
                    }

                }
            }
        }
    }

    .pos-menu {
        background-color: #fff;
        border-bottom: 1px solid #e5e5e5;
        padding: 0 20px;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;

            li {
                cursor: pointer;
                border-bottom: 2px solid transparent;
                display: flex;

                &.active {
                    border-bottom: 2px solid #000;
                }



            }


        }
    }

    .pos-status-bar {
        padding: 20px;
        display: flex;
        width: 100%;

        .statuses-more {

            .btn-more {
                margin-left: -3px;
                height: 36px;
                width: 100%;
                background: #BABAC8;
                color: white !important;
                border: none;
                outline: none;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;

                .btn-link {
                    color: white !important;
                }

                &.active {
                    background: #8696fc !important;
                }

            }

        }

        .statuses {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 5px;
            flex-wrap: nowrap;
            border-radius: 5px;
            overflow: hidden;
            width: 100%;



            .status {
                height: 36px;
                width: 100%;
                background: #BABAC8;
                color: white;
                width: 100%;
                text-align: center;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -18px;
                    /* Sağ tarafın genişliğini ayarlayabilirsiniz */
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 18px 0 18px 18px;
                    /* Üçgenin boyutunu ayarlayabilirsiniz */
                    border-color: white white white #BABAC8;
                    margin-right: 10px;
                    z-index: 9;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -20px;
                    /* Sağ tarafın genişliğini ayarlayabilirsiniz */
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 18px 0 18px 18px;
                    /* Üçgenin boyutunu ayarlayabilirsiniz */
                    border-color: #BABAC8 #BABAC8 #BABAC8 transparent;
                    margin-right: 10px;
                    z-index: 99;
                }

                &.active {
                    background: #8696fc;

                    &::before {

                        border-color: white white white #8696fc;

                    }

                    &::after {
                        border-color: #BABAC8 #BABAC8 #BABAC8 transparent;

                    }
                }

                &.active-passed {
                    background: $od-main;

                    &::before {

                        border-color: white white white $od-main;

                    }

                    &::after {
                        border-color: $od-main $od-main $od-main transparent;

                    }
                }

                &.active-prev {

                    &::after {
                        border-color: #8696fc #8696fc #8696fc transparent;

                    }
                }

                &:last-of-type {
                    &::after {
                        border: none !important;
                    }

                    &::before {
                        border: none !important;
                    }

                    border-radius: 0 5px 5px 0;
                }




            }
        }
    }

    .pos-description {
        padding: 0 20px;

        textarea {
            height: 60px;
            padding: 5px;
            border-radius: 10px;
        }
    }

    .pos-content-wrapper {
        padding: 20px;
        display: flex;
        gap: 20px;

        div {
            .card {

                background-color: transparent;
                border: none !important;




                .card-header {
                    background-color: #fff;
                    border-radius: 10px 10px 0 0;
                    background: #fff;
                    border: 1px solid #D9D9D9;



                    button {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        border: 1px solid #2D42FF;
                        color: #2D42FF;
                        height: 30px;
                        width: auto;
                        padding: 5px 10px;

                        font-size: 12.6px;

                        span {
                            color: #2D42FF;
                        }

                        &:hover {
                            background-color: #2D42FF;
                            color: #fff;

                            span {
                                color: #fff;
                            }
                        }
                    }
                }

                .card-body {
                    border-radius: 0 0 10px 10px;
                    background: #fff;
                    border: 1px solid #D9D9D9;
                    border-top: 1px solid transparent;
                }
            }
        }

        .pos-content-wrapper-left {
            width: 60%;
            border-radius: 5px;
        }

        .pos-content-wrapper-right {
            width: 40%;
            border-radius: 5px;
        }
    }
}

.disabled-div {
    pointer-events: none;
    opacity: 0.6;
}


.select-contracts-companies {
    div {
        border-radius: 10px !important;
    }
}