@import "../../assets/scss/config/_onedocs_variables.scss";

.cp-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .header {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 20px;

            .title {
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 1rem;
                text-align: center;
                color: $od-text-dark;
            }

            .description {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 1rem;
                text-align: center;
                color: $od-text-dark;
            }
        }

        .buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            gap: 40px;

            .button-wrapper {
                width: 185px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;

                &:first-child {
                    .button {
                        border: 1px dashed $od-focused;
                        color: #aaaaaa;

                        svg {
                            fill: $od-stroke-gray;
                        }
                    }


                }

                .button {
                    width: 185px;
                    height: 185px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    background-color: #fff;
                    border: 1px solid $od-focused;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    svg {
                        fill: $od-main;
                    }


                    &:hover {
                        background-color: #f5f5f9;
                        color: #303030;

                        svg {
                            fill: $od-focused;
                        }
                    }

                    .icon {
                        width: 80px;
                        font-size: 80px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        svg {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                    }


                }

                .text {
                    font-size: 1.1rem;
                    font-weight: 600;
                    text-align: center;
                    color: $od-text-dark;
                }

                .description {
                    font-size: .9rem;
                    font-weight: 400;
                    text-align: center;
                    color: $od-text-dark;
                }
            }

        }


    }
}