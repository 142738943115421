@import "../../assets/scss/config/onedocs_variables.scss";

$np-color: $od-stroke-gray;

.modal-upload {
    .add-document {

        padding: 20px;

        .import-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            .cdn-upload-source-select {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 15px;

                .btn {
                    border: 1px solid $od-stroke-gray;
                    background: white;
                    color: black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    .icon {
                        width: 30px;
                        height: 30px;
                        object-fit: contain;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;

                        margin-left: 5px;
                        cursor: pointer;
                    }
                }

                .btn.active {
                    border: 2px solid $od-stroke-gray;
                }
            }

        }


    }
}

.new-contract-modal {
    min-height: 300px;
    background-color: white;



    .modal-body {
        padding: 0;
        margin: 0;
        max-height: 100%;
        height: 100%;

        .process-content {
            height: 100%;

            .add-document {

                padding: 20px;

                .import-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .cdn-upload-source-select {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        gap: 15px;

                        .btn {
                            border: 1px solid $np-color;
                            background: white;
                            color: black;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;

                            .icon {
                                width: 30px;
                                height: 30px;
                                object-fit: contain;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100%;

                                margin-left: 5px;
                                cursor: pointer;
                            }
                        }

                        .btn.active {
                            border: 2px solid #2c42ff;
                        }
                    }

                }


            }

            .add-assign-user {
                height: 100%;
                min-height: 300px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;

                .buttons {
                    .submit-button {
                        height: 42px;
                        background-color: $od-focused;
                        color: white;
                        border-radius: 5px;
                        padding: 10px 29px;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        font-size: 1rem;
                        font-weight: 600;
                        transition: all 0.3s ease;

                        &:hover {
                            background-color: $od-focused;
                            color: white;
                            border-radius: 5px;
                            border: none;
                            outline: none;
                            cursor: pointer;
                            font-size: 1rem;
                            font-weight: 600;
                            transition: all 0.3s ease;
                        }
                    }
                }

                .title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    margin-bottom: 1rem;
                    text-align: center;
                }

                .user-list {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    flex-direction: row-reverse;
                    border: 1px solid $od-stroke-gray;
                    border-radius: 50px;
                    padding-left: 20px;
                    padding-top: 5px;
                    padding-bottom: 5px;


                    .dropdown {
                        width: 50px;
                        height: 50px;

                        .dropdown-menu {
                            margin-left: -45px;
                        }

                    }



                    .user {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-left: -10px;
                        z-index: 9;

                    }

                    .user-add {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-left: -10px;
                        z-index: 8;
                        background: white;
                        color: black;
                        border: 1px dashed $od-stroke-gray;

                        .plus-icon {
                            position: absolute;
                            bottom: 3px;
                            right: 9px;
                            background: #a9a9a9;
                            color: white;
                            border-radius: 100%;
                            width: 15px;
                            height: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }

            .add-record {
                padding: 30px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 20px;

                .input-title {
                    width: 100%;

                    input {
                        /* border: 0;
                        outline: none;
                        font-weight: 500;
                        padding-left: 0;
                        font-size: 20px;

                        &::placeholder {
                            color: $od-focused;
                            font-weight: 500;
                        } */
                    }
                }

                .input-type {
                    color: $od-focused;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    gap: 15px
                }

                .input-parties {
                    color: $od-focused;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    gap: 15px;





                }

                .input-description {
                    width: 100%;
                    color: $od-focused;

                    textarea {
                        color: $od-focused;
                        border-color: $od-stroke-gray;
                        min-height: 150px;
                        width: 100%;
                        border-radius: 10px;

                        &::placeholder {
                            color: $od-focused;
                            font-weight: 500;
                        }
                    }
                }

                .input-tags {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 5px;

                    .arrow-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border-radius: 0 10px 10px 0;
                        padding: 1px 10px;

                    }
                }

                .input-dates {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 20px;

                    .date-group {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;

                        .form-label {
                            width: auto;
                            margin: 0;
                            color: $np-color;
                            font-weight: 500;
                        }
                    }
                }

                .bottom-bar {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;

                    .left-side {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;

                        .footer-assign-user {
                            height: 100%;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: 20px;

                            .title {
                                font-size: 1.3rem;
                                font-weight: 600;
                                margin-bottom: 1rem;
                                text-align: center;
                            }

                            .user-list {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                flex-direction: row-reverse;
                                border: 1px solid $od-stroke-gray;
                                border-radius: 50px;
                                padding-left: 20px;
                                padding-top: 5px;
                                padding-bottom: 5px;


                                .dropdown {
                                    width: 50px;
                                    height: 50px;

                                    .dropdown-menu {
                                        margin-left: -45px;
                                    }

                                }



                                .user {
                                    height: 50px;
                                    width: 50px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 50%;
                                    margin-left: -10px;
                                    z-index: 9;

                                }

                                .user-add {
                                    height: 50px;
                                    width: 50px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 50%;
                                    margin-left: -10px;
                                    z-index: 8;
                                    background: white;
                                    color: $np-color;
                                    border: 1px dashed $od-stroke-gray;

                                    .plus-icon {
                                        position: absolute;
                                        bottom: 3px;
                                        right: 9px;
                                        background: #a9a9a9;
                                        color: white;
                                        border-radius: 100%;
                                        width: 15px;
                                        height: 15px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }

                        .parent-contract {

                            .dropdown-menu {
                                padding: 0 !important;

                                .search {
                                    padding: 0px !important;

                                    input {
                                        border: none;
                                        outline: none;
                                        border-radius: 4px;
                                        padding: 8px;
                                        width: 100%;
                                    }


                                }

                                .parent-item {
                                    padding: 8px !important;
                                    border-bottom: 1px solid #f5f5f5;
                                    border-radius: 4px;
                                    cursor: pointer;

                                    &:hover {
                                        background: #f5f5f5;
                                    }

                                    &:last-child {
                                        border-bottom: 0;
                                    }

                                    &:first-child {
                                        border-top: 1px solid #cccccc;
                                    }
                                }
                            }


                            .select-parent {
                                min-width: 62px;
                                height: 62px;
                                background: white;
                                color: $np-color;
                                border: 1px dashed $od-stroke-gray;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100px;

                                .selected-contract {
                                    margin-left: 5px;
                                }
                            }
                        }

                        .add-tags {


                            .select-tag {
                                min-width: 62px;
                                height: 62px;
                                background: white;
                                color: $np-color;
                                border: 1px dashed $od-stroke-gray;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100px;



                                .counter {
                                    padding: 5px;
                                    font-size: 12px;
                                    line-height: 12px;
                                    position: absolute;
                                    top: 0px;
                                    right: 2px;
                                    background: #a9a9a9;
                                    color: white;
                                    border-radius: 100%;
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }

                    }

                    .right-side {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 10px;

                        .submit-button {
                            height: 62px;
                            background-color: $od-focused;
                            color: white;
                            border-radius: 10px;
                            padding: 20px 30px;
                            border: none;
                            outline: none;
                            cursor: pointer;
                            font-size: 1rem;
                            font-weight: 600;
                            transition: all 0.3s ease;

                            &:hover {
                                background-color: $od-focused;
                                opacity: 0.8;
                                color: white;
                                border-radius: 10px;
                                border: none;
                                outline: none;
                                cursor: pointer;
                                font-size: 1rem;
                                font-weight: 600;
                                transition: all 0.3s ease;
                            }
                        }

                        .private-button {
                            width: 62px;
                            height: 62px;
                            background: white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px dashed $od-stroke-gray;
                            border-radius: 100px;
                            color: $np-color;

                            svg {
                                color: $np-color;
                            }

                        }


                    }
                }

            }
        }
    }
}

.solid-border {
    border: 1px solid $od-stroke-gray !important;
    color: $od-focused !important;

    svg {
        color: $od-focused !important;
    }
}

.button-selected {
    border: 1px solid $od-stroke-gray !important;
    color: $od-focused !important;

    svg {
        color: $od-focused !important;
    }
}


.react-select__placeholder {
    color: $np-color !important;
}

.react-select__single-value--is-disabled {
    color: $np-color !important;
}

/* .react-select__single-value {
    color: $np-color !important;
}

.react-select-container .react-select__value-container--has-value .react-select__single-value {
    color: $np-color !important;
} */

.select-contracts-type {
    color: $np-color !important;
}

.css-14el2xx-placeholder {
    color: $od-focused !important;
}

.card-title {
    color: $np-color !important;
    font-size: 14px;
    margin-bottom: 0;
    padding-bottom: 0;

}

.card-header {}

.card-body,
.input-custom-fields {
    width: 100% !important;
}

.react-tel-input {
    width: 100% !important;

    input {
        width: 100% !important;
    }
}

.form-label {
    margin-bottom: 0 !important;
}

.select-contracts-companies {
    width: 100% !important;


    * {
        border-radius: 0 !important;
    }
}

.np-upload-file-row {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    position: relative;
    min-width: 500px;
    border-radius: 10px;
    margin-bottom: 5px;

    &:nth-child(odd) {
        background-color: #f0f0f0;
        /* istediğiniz renk */
    }

    &:nth-child(even) {
        background-color: #f9f9f9;
        /* istediğiniz renk */
    }

    &:hover {
        background: #e5e5e5;


        .np-upload-file-row-meta {
            .np-upload-file-row-actions {
                display: block;
            }
        }

    }

    .np-upload-file-row-meta {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        .np-upload-file-row-actions {

            display: none;
        }


    }


}

.np-dotted-long-filetable {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cf-area-create-process {
    .row {
        div {
            margin-bottom: 6px;
        }
    }
}


@keyframes flash {
    0% {
        border-color: $od-focused;
    }

    50% {
        border-color: transparent;
    }

    100% {
        border-color: $od-focused;
    }
}

.textarea-flash {
    animation: flash 2s infinite;
}

.pc-status-select {}