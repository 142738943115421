@import "../../assets/scss/config/_onedocs_variables.scss";

.editorpopup-header {
    border-bottom: 1px solid $od-stroke-gray;
    height: 50px;
}

.editorpopup-body {

    .editorpopup-body-right {
        background: $od-bg-secondary;
        padding: 50px;

        .slate-wrapper {
            padding: 0;

            .slate-form {
                height: calc(100vh - 100px);
                border-right: 1px solid $od-stroke-gray;
                overflow: auto;
            }

            .slate {
                border: none !important;
                height: calc(100vh - 100px);
            }
        }
    }
}

.save-as-file-header {
    border-bottom: none !important;

    .save-as-file-header-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .save-as-file-header-content-icon {
            border: 1px solid $od-focused;
            background-color: $od-stroke-gray;

            border-radius: 4px;
            padding: 10px;
        }

        .save-as-file-header-content-title {
            margin-left: 10px;
            font-weight: 500px;
            font-size: 18px;
        }

    }
}