// Styles

// Main styles

@import './config/onedocs_variables.scss';

@import './bootstrap.scss';
@import './app.scss';

// Shreyu Icons
@import './icons.scss';


$one-blue: $od-focused;

//Disable Chrome two fingers back/forward swipe
html,
body {
  overscroll-behavior-x: none;
  background: #ffffff !important;
  font-family: 'DM Sans', sans-serif !important;
}

.table {
  border-color: $od-stroke-gray !important;
}

tbody#contracts-body {
  border-top: 1px solid $od-stroke-gray !important;
}

.table-responsive-md,
.table-responsive-lg,
.table-responsive-sm,
.table-responsive-xs {
  overflow-x: auto;
  max-width: 100%;
}

.bg-logo-color {
  background-color: #0144E4 !important;
  outline: #0144E4 !important;
  border: #0144E4 !important;
}

.text-logo-color {
  color: #0144E4 !important;
}

.btn-logo-color {
  background-color: #0144E4 !important;
  outline: #0144E4 !important;
  border: #0144E4 !important;
  transition: all 300ms linear;
  font-weight: 600;

  &:hover {
    background-color: #002c93 !important;
  }
}

.modal-shadow-border {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.modal-header {
  border-bottom: 1px solid #e9ecef8e;
  /* Bottom shadow */
}

// role="dialog" selector
/* .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start */
div[role=dialog]:not(.offcanvas):not(.rs-picker-daterange-menu) {
  display: block !important;
  backdrop-filter: blur(1px) !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.dots-loading {
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }

  @keyframes ellipsis {
    0% {
      content: ".";
    }

    33% {
      content: "..";
    }

    66% {
      content: "...";
    }
  }
}

.nav-pills {
  padding: 8px;
}

.nav-pills .nav-link.active {
  color: $od-text-light;
  background-color: $od-focused;
}


body[data-sidebar-size=condensed] .left-side-menu #sidebar-menu .menu-title {
  display: block !important;
}


body[data-sidebar-size=condensed] .left-side-menu #sidebar-menu>ul>li:hover>.collapse>.nav-second-level-position-top,
body[data-sidebar-size=condensed] .left-side-menu #sidebar-menu>ul>li:hover>.collapsing>ul.nav-second-level-position-top {
  bottom: calc(-100% + 109px);
}


.one-btn {
  background-color: rgba($color: $one-blue, $alpha: 1.0);
  color: white;
  border-radius: 10px;
  padding: 10px 29px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba($color: $one-blue, $alpha: .8);
    color: white;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
  }
}

.one-btn.outlined {
  background-color: transparent !important;
  color: rgba($color: $one-blue, $alpha: 1.0) !important;
  border-radius: 10px;
  padding: 10px 29px;
  border: 1px solid rgba($color: $one-blue, $alpha: 1.0) !important;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background-color: transparent !important;
    color: rgba($color: $one-blue, $alpha: .8) !important;
    border-radius: 10px;
    border: 1px solid rgba($color: $one-blue, $alpha: .8) !important;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
  }
}



.btn-onedocs-primary {
  background: $od-focused;
  border-color: $od-focused;
  color: #fff;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.8;
  }

  .dropdown-toggle:focus {
    opacity: 0.8;
  }

}

.btn-onedocs-secondary {
  background: $od-main;
  border-color: $od-main;
  color: $od-text-light;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.8;
  }

}

.btn-primary {
  background: $od-focused;
  border-color: $od-focused;
  color: #fff;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.8;
  }

  .dropdown-toggle:focus {
    opacity: 0.8;
  }

}

.btn-outline-primary {
  background: transparent;
  border-color: $od-focused;
  color: $od-focused;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.8;
  }

  .dropdown-toggle:focus {
    opacity: 0.8;
  }

}

.btn-secondary {
  background: $od-main;
  border-color: $od-main;
  color: $od-text-light;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.8;
  }

}


.version-modal-content {
  border-radius: 20px !important;
}


.rs-toggle-checked .rs-toggle-presentation {
  background-color: $od-focused !important;
}

.cf-area-create-process .row div {
  margin-bottom: 0px !important;
}

.od-table {
  thead {
    background: $od-bg-secondary !important;

    tr {
      border-color: $od-stroke-gray !important;
      border-top: none;
    }
  }

  tbody {
    tr {
      background: $od-bg-white !important;
      border-color: $od-stroke-gray !important;
    }
  }
}

.table-bordered {
  border-color: $od-stroke-gray !important;
}

.card {


  .card-body {
    background: $od-bg-white !important;
    border-bottom: 1px solid $od-stroke-gray !important;
  }

  .card-footer {
    background: $od-bg-secondary !important;
    border-bottom: 1px solid $od-stroke-gray !important;
  }
}

.border {
  border-color: $od-stroke-gray !important;
}

.form-label {
  color: $od-text-dark !important;
}

.card-title h1,
h2,
h3,
h4,
h5,
h6 {
  color: $od-text-dark !important;
}