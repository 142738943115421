//Create variable for colors
$approve-red: #F0233B;
$approve-green: #2EB818;
.internal-approve-modal-dialog{
    width: 1600px;
    max-width: 100%;
   

}
.internal-approve-popup {
    padding: 0;
    width: 100%;
    height: calc(100vh - 9rem);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .internal-approve-left {
        width: 25%;
            height: 100%;
            overflow-y: auto;
            padding: 10px;
            background: #eeeeee;
        .top{
            
            position: relative;
                height: 70%;
                overflow-y: auto;
            
                .internal-approve-left-versions {
                    display: flex;
                    flex-direction: column;
            
                    .internal-approve-left-version {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 12px;

                        margin-bottom: 15px;
                       
            
                        &:hover {
                            .internal-approve-left-version-selector {
                                visibility: visible!important;
                                transition: all 0.3s linear;
                            }
                        }

                        &.active {
                            .internal-approve-left-version-circle {
                                .circle {
                                    background: #2c42ff;
                                }
                            }
                        }
            
                        &:last-child {
                            .internal-approve-left-version-circle {
                                .top-connector {
                                    display: none;
                                }
                            }
                        }
            
            
                        .internal-approve-left-version-number {
                            margin-left: 30px;
                            width: 20px;
                            text-align: right;
                        }
                        .internal-approve-left-version-selector {
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            .selector-number{
                                width: 24px;
                                height: 24px;
                                border-radius: 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 2px solid #2c42ff;
                            }
                            label.disabled{
                                cursor: not-allowed;
                                svg{
                                    color: #cccccc;
                                }
                            }
                        }
            
                        .internal-approve-left-version-selector:not(.showSelector) {
                            visibility: hidden;
                        }
            
                        .internal-approve-left-version-circle {
                            position: relative;
            
                            .circle {
                                display: block;
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                background: #dadada;
            
                            }
            
                            .top-connector {
                                position: absolute;
                                display: block;
                                width: 1px;
                                height: 33px;
                                background: #dadada;
                                left: 9px;
                            }
            
            
            
            
                        }
            
                        .internal-approve-left-version-title {
                            .title{
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                margin: 0;
                                width: 150px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .date{
                                font-weight: 300;
                                font-size: 10px;
                                line-height: 10px;
                                color: #868585;
                                margin: 0;
                            }
            
                        }
                    }
            
            
                }
        }
        .bottom{
            height: 30%;
            width: 100%;
        }
    }

    .internal-approve-center {
        width: 50%;
        height: 100%;
        padding: 10px;
        .internal-viewer{
            width: 100%;
            height: 100%;
           
            .google-drive-approve-iframe {
                width: 100%;
                height: 100%;
            }
        }
        
    }

    .internal-approve-right {
        width: 25%;
        height: 100%;
        overflow-y: auto;
        padding: 10px;
        background: #eeeeee;
        .internal-approve-buttons{
            
            width: 100%;
            .external-approve-actions {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 20px;
                    margin-top: 20px;
                    padding: 20px;
            
                    .button-reject {
                        button{
                            background: $approve-red;
                            border: $approve-red;
                        }
                        width: 100%;
                    }
                    .button-approve-dd {
                        button{
                            background: $approve-green;
                            border: $approve-green;
                        }
                        width: 100%;
                    }
            
                    .button-approve {
                        background: $approve-green;
                        border: $approve-green;
                        width: 100%;
                    }
            
                    .button-change-assign {
                        width: 100%;
                    }
                }
        }
        .approve-files{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .approve-file{
                
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 20px;
                overflow: hidden;
                border-radius: 7px;
                
                .approve-file-header {
                        padding: 0 10px;
                        border-radius: 5px 5px 0 0;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        overflow: hidden;
                
                        .approve-file-header-title {
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap:6px;
                            padding-left: 9px;
                        }
                
                        .approve-file-header-action {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 5px;
                            color: white;
                            button{
                                svg{
                                    fill: white;
                                    color: white;
                                }
                            }
                        }
                    }

                .approve-file-body{
                    width: 100%;
                    background: #fff;
                    border-radius: 0 0 5px 5px;
                    .approve-file-attachments {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 5px;
                            background: #fff;
                            padding: 15px;
                            border-radius: 5px;
                        }
                    
                        .approve-file-steps {
                            padding: 15px;
                            padding-top: 0;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 10px;
                    
                            .approve-file-step {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                gap: 5px;
                                border-radius: 7px;
                                overflow: hidden;
                                border: 1px solid #ccc;
                                background: #fff;
                                border-radius: 7px;
                    
                                .approve-file-step-header {
                                    border-radius: 5px 5px 0 0; 
                                    background: #ccc;
                                    padding-left: 17px;
                                    color: #fff;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-between;
                                    overflow: hidden;
                    
                                    .approve-file-step-header-title {
                                        font-weight: bold;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        gap:2px;
                                    }
                    
                                    .approve-file-step-header-action {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: flex-end;
                                        gap: 5px;
                                    }
                                }
                    
                                .approve-file-step-body {
                                    width: 100%;
                                    padding: 10px;
                    
                                    .approve-file-step-users {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        gap: 15px;
                    
                                        .approve-file-step-user {
                    
                                            width: 100%;
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            justify-content: space-between;
                                            gap: 5px;
                    
                                            .approve-file-step-user-detail {
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                justify-content: flex-start;
                                                flex-wrap: nowrap;
                                                gap: 5px;
                    
                                                .approve-file-step-user-avatar {
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 50%;
                                                    position: relative;
                                                    z-index: 9;
                    
                                                    .top-connector {
                                                        width: 2px;
                                                            height: 65%;
                                                            background: #c1c1c1;
                                                            position: absolute;
                                                            margin-left: calc(-50% - 1px);
                                                            top: -50%;
                                                            z-index: -1;
                                                    }
                                                }
                                            }
                    
                                            .approve-file-step-user-status {
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
                            .approve-file-step-active {
                                border: 2px solid #0144E4;
                                border-radius: 8px !important;
                                overflow: hidden;
                            }
                        }
                }
                
            }
        }
        .approve-file-active{
            border: 2px solid #0144E4!important;
            border-radius: 8px !important;
            overflow: hidden;
        }
        
    }
}

.approve-file-step-user-change-requested{
    position: relative;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 5px;
    .added-file{
        display: block;
        width: 30px;
        height: 30px;
        position: relative;
        z-index: 9;
        display: flex;
            align-items: center;
            justify-content: flex-start;
        .top-connector {
            width: 2px;
                height: 70%;
                background: #c2c2c2;
                position: absolute;
                left: calc(50% - 6px);
                top: -50%;
                z-index: -1;
            }
    }
}

.bg-file-approved{
    background: $approve-green;
    color: #fff;
}

.bg-file-rejected{
    background: $approve-red;
    color: #fff;
}

.bg-file-pending{
    background: #040025;
    color: #fff;
}
.bg-file-created {
    background: #040025;
    color: #fff;
}

// data-tooltip-unhoverable="true"

[data-tooltip-unhoverable="true"] {
    display: none!important;
}

.compare-result{

    .contentA4 {
        width: 210mm;
        height: auto;
        margin: 10mm auto;
        padding: 10mm;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    del {
            background: rgba(255, 0, 0, 0.495);
        }
    
        ins {
            background: rgba(0, 255, 0, 0.495);
        }
    
        .a4content {
            width: 210mm;
            height: auto;
            margin: 10mm auto;
            padding: 10mm;
            border: 1px #D3D3D3 solid;
            border-radius: 5px;
            background: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
    
        table {
            border-collapse: collapse;
            /* Tablo hücrelerinin sınırlarının birleştirilmesi */
            width: 100%;
            /* Tablo genişliği */
        }
    
        table,
        th,
        td {
            border: 1px solid black;
            /* Tablo, başlık hücreleri ve veri hücrelerinin sınırları */
        }
    
        th,
        td {
            padding: 8px;
            /* Hücre içeriğinin içeriden boşluğu */
            text-align: left;
            /* Hücre içeriğinin hizalanması */
        }
    
        th {
            background-color: #f2f2f2;
            /* Başlık hücrelerinin arkaplan rengi */
        }
}