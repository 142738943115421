// Variables
//
// custom-variables
//

// Color Palette
/* 
#103740  #72F2B6 #A0D9B9 #60BF78 #F2F2F2 #F4F4ED
*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');


$od-font-family: 'DM Sans', sans-serif;
// OneDocs Colors
$od-main: #7ADAB9;
$od-focused: #255957;

$od-bg-white: #FFFFFF;
$od-bg-secondary: #f4f0ed;
$od-bg-disabled: #EFF1F2;

$od-stroke-gray: #E4E5E8;
$od-stroke-green: #255957;

$od-text-dark: #000022;
$od-text-light: #FFFFFF;
$od-text-error: #BB0A21;