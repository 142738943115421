@mixin dotdotdot($width, $display: block) {
    width: $width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: $display;
}

.reminder-flat-scroll {
    .infinite-scroll-component {
        display: flex;
        gap: 14px;

        .reminder {
            width: 100%;

            .reminder-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
                padding: 5px 10px;
                border: 1px solid #eeeeee;
                border-radius: 5px;


                &:hover {
                    background-color: #eeeeee;
                }

                .reminder-detail {
                    display: flex;
                    flex-direction: column;

                    h4 {
                        font-size: 14px !important;
                        color: #4b4b5a;
                    }

                    .rem-date {
                        font-size: 14px !important;
                        color: #a1a8b0;
                    }

                    .reminder-detail-meta {
                        .title {

                            font-weight: 400;
                            @include dotdotdot(200px);
                        }

                        .description {
                            font-size: 14px;
                            @include dotdotdot(200px);
                        }
                    }

                    .reminder-users {
                        padding: 4px;
                    }

                }

                .reminder-action {}

            }
        }
    }
}



.reminder-guests-list {
    min-height: 100px;
    border: 1px dashed #eeeeee;
    padding: 10px;
}