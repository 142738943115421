.aprrove {
    max-height: 800px;
    overflow-y: auto;
    position: relative;

    .header-fixed {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    }

    .approve__footer {
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px 20px;
        padding: 20px 0 0 0;
        margin-bottom: 20px;
        position: relative;
        /*         position: -webkit-sticky;
            position: sticky; */
        top: 0;

        .approve__header__top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            /*       position: -webkit-sticky;
                    position: sticky; */
            top: 0;

            .approve-message-textarea {
                width: 100%;
                height: auto;
                min-height: 80px;
                border: 1px solid hsl(0, 0%, 80%);
                border-radius: 0px;
                padding: 8px;
                font-size: 14px;
                color: #000;
                border-radius: 5px;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #a1a8b0 !important;
                    font-size: 14px;
                }
            }
        }

        .approve__footer__title {
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding: 20px;
            padding-bottom: 0;
        }
    }

    .approve__header {
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        /*         position: -webkit-sticky;
            position: sticky; */
        top: 0;

        .approve__header__top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            /*       position: -webkit-sticky;
                    position: sticky; */
            top: 0;

            .approve-message-textarea {
                width: 100%;
                height: auto;
                min-height: 80px;
                border: 1px solid hsl(0, 0%, 80%);
                border-radius: 0px;
                padding: 8px;
                font-size: 14px;
                color: #000;
                border-radius: 5px;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #a1a8b0 !important;
                    font-size: 14px;
                }
            }
        }

        .approve__header__body {
            width: 100%;
            background: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 20px 5px 20px;
            /*   position: -webkit-sticky;
            position: sticky; */
            top: 0;

            .approve__header__title {
                font-size: 16px;
                font-weight: 400;
                color: #000;
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                input {
                    width: 100%;
                    height: 32px;
                    border: 1px solid #e9e9e9;
                    border-radius: 0px;
                    padding: 0 10px;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .approve__header__actions {
                display: flex;
                align-items: center;

                .approve__header__actions__button {
                    color: #2d42ff;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 14px;
                }
            }
        }
    }

    .steps {
        padding: 20px;
        padding-top: 0;

        .step {
            /* padding: 20px; */
            width: 100%;
            /* background: #e9e9e9; */
            margin-bottom: 25px;
            border-radius: 5px;

            .step-number {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px !important;
            }

            .recipients-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .recipients {
                    width: 100%;
                    background: #ffffff;
                    border: 1px solid #e9e9e9;

                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 5px;
                    border-radius: 5px;

                    .recipients-new {
                        width: 240px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        input {
                            text-align: left;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                            border-color: #e9e9e9;
                            outline: 0;
                            border: 0;
                            width: 100%;
                        }

                        .add-recipient-button {
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;
                            border: 1px solid #050027;
                            outline: 1px solid #050027;
                        }
                    }

                    .recipient-group {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .recipient-label {
                            background: #f5f5f9;
                            color: #555555;
                            padding: 5px 10px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .recipient-settings {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 10px;

                            .form-check-inline {
                                margin-right: 0;
                                margin-bottom: 0;
                                line-height: 14px;
                            }

                            .btn {
                                padding: 0;
                                width: 20px;
                                height: 20px;
                                color: #303030;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 12px;
                                cursor: pointer;

                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }

            .step-settings {
                padding: 0;

                padding-top: 0px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                font-size: 13px;
                font-weight: 400;
                line-height: 13px;
            }
        }
    }

    .recipient-suggestions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        color: blue;
        width: 100%;
    }

    /* ENDLESS ROTATE */
    .rotate {
        animation: rotate 1.5s linear infinite;
    }

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
}

.form-check-label {
    line-height: 23px;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.3rem;
}

.floating-send-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    background: #2d42ff;
    color: #ffffff;
    border-radius: 8px;
    width: auto;
    padding: 3px 11px;
    height: 40px;
    min-width: 77px;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #2d42ff;

    &:hover {
        background: #050027;
    }
}

.floating-cancel-button {
    position: absolute;
    bottom: 20px;
    right: 103px;
    min-width: 77px;
    z-index: 999;
    background: #fff;
    border: 1px solid #050027;
    color: #050027;
    border-radius: 8px;
    width: auto;
    padding: 3px 11px;
    height: 40px;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #050027;
        color: #fff;
    }
}

.editable-title {
    width: 100%;

    .editable-title-inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        gap: 8px;

        h4 {
            font-size: 16px;
            line-height: 16px;
            color: #ffffff;
        }

        .editable-input-text {
            padding: 10px;
            background-color: transparent;
            color: #ffffff;
            font-size: 16px;
            width: 100%;
            height: 32px;
            border: 1px solid #fcfcfc1e;
            border-radius: 0px;
            padding: 0 10px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: #ffffff !important;
                font-size: 16px;
            }
        }
    }
}

.flow-template-dd {
    .divider {
        margin-top: 15px;
        margin-bottom: 5px;
        width: 100%;
        background: #cccccc;
        height: 1px;
    }

    .flow-template-actions {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
}

.dotted-f-dd {
    width: 165px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.steps-edit {
    position: relative;
    padding: 20px;
    padding-top: 0;

    .floting-close-icon {
        position: absolute;
        top: 0px;
        right: 5px;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        h4 {
            max-width: 400px;
            text-align: center;
        }
    }

    .step {
        padding: 10px;
        width: 100%;
        background: #fff;
        border: 1px solid #e9e9e9;
        margin-bottom: 20px;
        border-radius: 5px;

        .step-number {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                color: blue;
            }
        }

        .recipients {
            background: #ffffff;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 5px;
            border-radius: 5px;
            margin-bottom: 10px;

            .recipient {
                width: 100%;
                overflow: hidden;
                border-radius: 5px;

                .recipient-group {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .recipient-label {
                        background: #f1f1f9;
                        color: #303030;
                        padding: 5px 10px;
                        border-radius: 5px;
                    }

                    .recipient-settings {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 10px;

                        .form-check-inline {
                            margin: 0;
                            padding: 0;
                            line-height: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 3px;

                            .form-check-input {
                                margin: 0;
                                padding: 0;
                            }

                            .form-check-label {
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 13px;
                                white-space: nowrap;
                            }
                        }

                        .btn {
                            padding: 0;
                            width: 20px;
                            height: 20px;
                            color: #303030;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;
                            cursor: pointer;

                            &:hover {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }

        .step-settings {
            padding: 10px;
            height: 50px;
            padding-left: 0 !important;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: #e9e9e9;
    margin: 20px 0;
}

@media (min-width: 576px) {
    .modal-dialog {
        &:not(.modal-xl) {
            max-width: 600px;
            margin: 1.75rem auto;
        }
    }
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}